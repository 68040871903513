/***********************************************************************************
 * Module:          envConfig
 *
 * Description:     STAGING environment config. The appropriate environment config
 *                  is taken at build time depending on the --env envName parameter
 ***********************************************************************************/

/**
 * Use CommonJS module syntax as this module is
 * dynamically loaded based on build env parameter
 */
module.exports = {

  aodocsInstallUrl: 'https://welcome.aodocs-staging.com/dms/',

  clientIds: {
    aodocs: '563231141203-603dqvit3u46bq8vs041bn5ecnudgqe5.apps.googleusercontent.com',
    smartbar: '287001457024-l2n60j01d8ejre7rnllorcagvdecmf0m.apps.googleusercontent.com'
  },

  allowedExtensionIds: {
    sb: 'jdfejjabmfoicpgmajgllellipniiaml',
    ufo: 'imcibeelfmccdpnnlemllnepgbfdbkgo',
    awd: 'gfdajlfjianmgkafnfbeedenillaokej'
  }

};
